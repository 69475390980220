<template>
  <v-container>

    <v-row justify="center" class="noprint">
      <!-- <h1 class="noprint">QR Demo</h1> -->
      <v-btn class="noprint" color="success" @click="generate2()">Generate</v-btn>
    </v-row>

    <!-- <v-row justify="center">    
      
        <v-text-field v-model="QRValue" label="Enter Text to generate QR Code:"></v-text-field>
          
    </v-row>

    <v-row justify="center" style="height: 300px;">
      <qrcode-vue :value="QRValue" :size="300" level="H" v-if="QRValue"/>
    </v-row> -->

    <!-- <v-spacer style="height: 300px;"></v-spacer> -->
  <!-- <div class="noprint">
    <v-row justify="center">
      <h1>Click Generate to create QR Codes</h1>
    </v-row>

    <v-spacer style="height: 50px;"></v-spacer>

    <v-row justify="center">
      <v-btn color="success" @click="generate2()">Generate</v-btn>
    </v-row>

    <v-spacer class="noprint" style="height: 50px;"></v-spacer>

  </div> -->

  <!-- <v-btn class="noprint" color="success" @click="generate2()">Generate</v-btn> -->


    <!-- <v-row>
      <ul style="list-style-type: none">
        <li v-for='(code, index) in QRCodes' v-bind:key="index">
          <qrcode-vue :value="code" :size="75" level="H"/>
                    {{index}} : {{ code }}
          <v-spacer style="height: 50px;"></v-spacer>
        </li>
      </ul>
    </v-row> -->

    <v-row>
      <table class="noborder">
        <tr v-for='(row, index) in QRTable' v-bind:key="index">
          <td v-for='(code, index) in QRTable[index]' v-bind:key="index" class="noborder">
            <qrcode-vue :value="code" :size="85" level="H" render-as="canvas"/>
            
          </td>
        </tr>
      </table>
    </v-row>
    
    
  </v-container>
</template>

<script>
import QrcodeVue from 'qrcode.vue'

export default {
  data() {
    return {
      QRValue: null,
      QRCodes: [],
      QRTable: [],
      codesArray: []
    }
  },
  components: {
    QrcodeVue,
  },
  methods: {
    createString: function() {
    const characters = '1234567890'
    let result = ''
    const charactersLength = characters.length
    for ( let i = 0; i < 16; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result

    },
    generate: function() {
      this.QRCodes = []
      console.log('Generating QR Codes...')
      for (let i = 0; i < 8; i++) {
        let codes = this.createString()
        //console.log(`Code ${i}: `, codes)
        this.QRCodes.push(codes)
      }
      //console.log("this.QRCodes", this.QRCodes)
      return this.QRCodes     
    },
    generate2: function() {
      console.log('gererate2 function...')
      this.QRTable = []
      for (let i = 0; i < 10; i++){
        //console.log('i = ', i)
        let response = this.generate()
        this.QRTable.push(response)
      }
      console.log("this.codesArray", this.QRTable)
    }

  }
}

  
</script>


<style>
@media print {
  .noprint {
    visibility: hidden;
    margin-top: 0.13in;
  }
  .noborder {
    border: 0;
  }

}

/* @page {
  size: 8.5in 11in;
  margin-left: 0.25in;
  margin-top: 0.5in;
  margin-right: 0.25in;
  margin-bottom: 0.5in;
} */

@page {
  margin-top: 0;
  padding-top: 0;
  padding-bottom: 0;
  size: 8.5in 11in;
  margin-left: 0.25in;
  margin-right: 0.25in;
  
}

table, th, td {
  border: 1px solid black;
  border-spacing: 0;
  padding: 0;
  line-height: 0;
}

td {
  width: 1in;
  height: 1in;
  text-align: center;
}




</style>